@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  @apply scroll-smooth;
}

body {
  height: 100%;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

::selection {
  @apply bg-sky-500
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 300ms ease;
}

.underline-animation,
.blog-content a {
  position: relative;
  text-decoration: none;
}

.underline-animation::after,
.blog-content a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
  transform: scaleX(0);
}

.underline-animation:hover::after,
.blog-content a:hover::after {
  transform: scaleX(1);
}

* {
  box-sizing: border-box;
}

.heading {
  font-family: "Raleway", sans-serif;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.banner {
  padding-top: 92px;
}

.btn-primary {
  @apply transition-all duration-300;
}

.home-triangle {
  clip-path: polygon(51% 40%,0 0,100% 0);
}

.dashboard-visible {
  transition: opacity 300ms ease-in 300ms, height 0s ease-in 300ms;
}

.dashboard-invisible {
  transition: opacity 300ms ease-in 0s, height 0s ease-in 300ms;
}

.dashboard-icon-mask {
  -webkit-mask-image: url(../public/img/icons/heart-outline.svg);
  mask-image: url(../public/img/icons/heart-outline.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.carousel-indicators {
  @apply rounded-full w-fit mx-auto p-2 absolute right-0 bottom-0 left-0 flex justify-center items-center mb-2;
}

.carousel-indicators [data-bs-target] {
  @apply border-2;
}

.img-standard {
  @apply w-5/6 mx-auto rounded-2xl;
}

.carousel-control-prev,
.carousel-control-next {
  @apply w-max flex items-center justify-center p-0 text-center border-0;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  @apply opacity-90;
}

.carousel-control-next:hover,
.carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-prev:focus {
  @apply outline-none no-underline;
}

.scrollbar::-webkit-scrollbar, .scrollbar-main::-webkit-scrollbar {
  @apply w-2;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3rem;
  @apply bg-teal-700 border-teal-700 border-2
}

.scrollbar-main::-webkit-scrollbar-thumb {
  border-radius: 3rem;
  @apply bg-slate-800 border-slate-800 border-2
}

.scrollbar::-webkit-scrollbar-track-piece:end, .scrollbar-main::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

.scrollbar-main::-webkit-scrollbar-track-piece:end {
  margin-bottom: 1rem;
}

.scrollbar::-webkit-scrollbar-track-piece:start, .scrollbar-main::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.scrollbar-main::-webkit-scrollbar-track-piece:start {
  margin-top: 1rem;
}

.collapse.show {
  visibility: visible !important;
}

.blog-content p {
  @apply mb-4;
}

.blog-content h2 {
  @apply text-4xl font-semibold mb-4 mt-8;
}

.blog-content h3 {
  @apply text-2xl font-medium my-4;
}

.blog-content ul {
  @apply list-disc ml-8;
}

.blog-content img {
  @apply w-3/4 mx-auto;
}

.form-input {
  @apply mb-4 px-4 py-2 border border-transparent border-b-2 border-b-slate-400 transition-all;
}

.form-input:hover,
.form-input:focus-within {
  @apply border-slate-400;
}

.form-input:focus-within {
  @apply outline-none;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: white;
    color: black;
  }

  input {
    background: white;
  }

  textarea {
    background: white;
  }
}
